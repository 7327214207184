<template>
 <div>
     <h2 class="dbhead">Add/Edit Credit Card Information for <span class="swcp-em">{{ current_user }}</span></h2>

     <div class="container" v-if="this.acc_role!='Secondary'">
        <div class="row">
            <CardsOnFile v-on:change-card="changeCard" :key="gatekeeper"> </CardsOnFile>
        </div>
        <div v-if="this.which_card == 0" >
          <form v-on:submit.prevent="addCard">
               <CreditCard v-if="this.which_card == 0"
                      title="Add a New Card"
                      v-model:ccname="userData.ccname"
                      v-model:ccnumber="userData.ccnumber"
                      v-model:address="userData.address"
                      v-model:city="userData.city"
                      v-model:state="userData.state"
                      v-model:zipcode="userData.zipcode"
                      v-model:country="userData.country"
                      v-model:expdate="userData.expdate"
                      v-model:cvv="userData.cvv"
                      v-model:cardtype="userData.cardtype"
                      v-model:problems="problems"
                      v-on:clear-probs="clearProblems"
                      :key="cckey">
                </CreditCard>
                <div class="row offset-sm-4 col-sm-4 justify-content-md-center">
                       <ErrorMessage :error="problems.auto_bill" />
                </div>
                <div v-if="this.which_card == 0" class="tapdown form-check offset-sm-4 col-sm-8" >
                   <label class="form-check-label">Use card for Auto-billing? &nbsp;&nbsp;</label>
                   <div class="form-check form-check-inline">
                        <input class="form-check-input" 
                                  type="radio"
                                  name="auto_bill" 
                                  v-model="auto_bill"
                                  value=1 aria-label="Yes" rules="required" />
                        <label class="form-check-label">Yes &nbsp;&nbsp;</label>
                        <input class="form-check-input" 
                                  type="radio"
                                  name="auto_bill" 
                                  v-model="auto_bill"
                                  value=0 aria-label="No"/>
                        <label class="form-check-label">No  &nbsp;&nbsp; </label>
                   </div>

                   <div class="form-group">
                      <div class="offset-sm-4 col-sm-4">
                           <button v-if="this.which_card == 0"
                                   class="btn btn-outline-primary"
                                   type="submit">Add Card</button>
                      </div>
                      <div class="col-sm-4"></div>
                  </div>
                </div>
   
          </form>
        </div>
        <div class="row" v-if="this.which_card != 0">
              <h3>Edit Card</h3>
              <UpdateCreditCard 
                              v-bind:which_card = parseInt(which_card)
                              v-bind:card_method="card_method"
                              v-on:updated-card="newCardList"
                              v-on:deleted-card="newCardList"
                              v-on:message="passthruEmit"
                              :key="upkey">
              </UpdateCreditCard>
       </div>
    </div>
  <div v-else>
       <h3>Secondary accounts don't have access to billing information</h3>
  </div>
</div>
</template>

<script>
   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core'
   import { required, numeric, helpers } from '@vuelidate/validators';
   import { luhn, valid_mmyy_date, valid_expiration_date, positive_amount } from '@/helpers/utils.js';
   import ErrorMessage from '@/components/ErrorMessage.vue'
   import CardsOnFile from '@/components/CardsOnFile.vue'
   import CreditCard from '@/components/CreditCard.vue'
   import UpdateCreditCard from '@/components/UpdateCreditCard.vue'

   export default {
      name: 'EditCC',
      components: { ErrorMessage, CardsOnFile, CreditCard, UpdateCreditCard },
      validations: {
        auto_bill: {  required: helpers.withMessage('Required', required) }
      },           
      emits: [ 'message' ],
      data () {
       return {
           v$: useVuelidate(),
           fields: [ 'amount', 'ccname', 'ccnumber', 'address', 'city', 
                     'state', 'zipcode', 'country', 'expdate', 'cvv',
                     'cardtype', 'account', 'email', 'comments', 'auto_bill' ],

           is_visible: false,
           acc_role: 'Secondary',
           verrors: [],
           gatekeeper: 1,
           cckey: 1,
           upkey: 1,
           auto_bill: '',
           acctData: {
               account: ''
           },
           userData: {
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: 'US',
               expdate: '',
               cvv: '',
               cardtype: '',
               account: '',
               add_payment: 1,
               save_card: 0,
               auto_bill: '',
               use_this_card: ''
           },
           problems: { 
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: '',
               expdate: '',
               cvv: '',
               cardtype: '',
               save_card: 0,
               auto_bill: ''
           },  
           which_card: 0,
           card_method: {},
           response_title: '',
           response_body: '',
           status: '',
           idinfo: ''
        }
      },
      watch: {
            current_user: function (newUser, oldUser) {
                 this.$router.push({ name: "home"});
            }
      },
      mounted() {
         this.setupPage();
      },

      methods: {
         clearProblems() {
            this.fields.forEach ((item) => { 
               this.problems[item] = ''; 
            });
            this.cckey++;
         },        
         changeCard(which, method) {
            this.card_method = method;
            this.which_card = which;
         },
         newCardList() {
            this.gatekeeper++;
            this.which_card = 0;
         },
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         passthruEmit() {
            this.$emit('message');
         },
         setupPage() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var account = this.current_user;
            var url = "api/account-role/" + account;
            axios.get(url, config )
            .then (
                 response => {
                    this.acc_role = response.data.data.account_role;
                 })
            .catch(error => {
                   if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                   }
                   this.status = error;
            })

         },
         setDisappear() {
            this.is_visible = false;
         },
         async addCard( { resetForm }) {
            var url = "api/add-card";
            var title = "";
            var body = "";
            var message, property;
            var msg;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         }; 
            this.userData.account = this.current_user;
            this.userData.auto_bill = this.auto_bill;
            const result = await this.v$.$validate();
            if (! result) {
                  msg = "Validation problems found:";
                  this.clearProblems();
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                  });
                  this.cckey++;
                  console.log(msg);
            } else {
                axios.post(url, this.userData, config )
                .then (
                       response => {
                             if (response.data.status) {
                                 title = "Success";
                                 body = "The Card has been added";
                                 this.responseMessage(title, body, 'review', 'info', '', []);
                                 this.v$.$reset();
                                 this.newCardList();
                                 this.clearProblems();
                                 this.cckey++;
                             } else {              
                                 title = "Failed";
                                 body = response.data.message;
                                 this.responseMessage(title, body, 'modal', 'serious', '', []);
                             }
                       }) 
                .catch(error => {
                             var errmsg = "Unable to add this card " + error;
                             this.responseMessage("Failed", errmsg, 'modal', 'serious', '', []);
                       }) 
       
            }
         }
      },
      computed: {
           user () { return this.$store.state.user; },
           current_user () { return this.$store.state.current_user; },
          jwt () { return this.$store.state.jwt; }
      }
    }
</script>
      
<style> 
::placeholder {  
  color: #a2bbd3 !important;
}          
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}              
.sam-info.card-body {
   background-color: #def6c5;
}              
.swcp-comp {   
   margin-left: 10%;
   width: 80%;
}
.swcp-comp td, .swcp-comp th {
   padding: .25em;
   width: 25%;
}
.tapdown {
   margin-bottom: 15px;
}
</style>

