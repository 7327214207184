<template>

  <div class="col-4"></div>
  <div class="col-sm-12">
  <form  v-on:submit.prevent="processPayment" v-on:clear-probs="clearProbs"
          ref="form">
    <div class="row justify-content-md-center">
       <div class="col-9 offset-3">
         <ErrorMessage :error="problems.amount" />
       </div>
    </div>
    <div class="form-group row">
         <label class="col-sm-3" for="amount">Amount</label>
         <input name="amount" class="form-control col-sm-8" type="text" v-model="userData.amount"
                id="amount" placeholder="Amount" aria-label="Amount" />
    </div>
    <div class="row">
       <CreditCard v-if="this.which_card == 0" 
                   title=""
                   v-model:ccname="userData.ccname"
                   v-model:ccnumber="userData.ccnumber"
                   v-model:address="userData.address"
                   v-model:city="userData.city"
                   v-model:state="userData.state"
                   v-model:zipcode="userData.zipcode"
                   v-model:country="userData.country"
                   v-model:expdate="userData.expdate"
                   v-model:cvv="userData.cvv"
                   v-model:cardtype="userData.cardtype"
                   v-model:problems="problems"
                   v-on:kickit="kickMe"
                   :key="cckey" >
        </CreditCard>
    </div>

    <div class="row justify-content-md-center">
       <div class="col-9 offset-3">
         <ErrorMessage :error="problems.card_status" />
       </div>
    </div>
    <div class="row">
        <div v-if="this.which_card == 0" class="tapdown form-check offset-sm-4 col-sm-8" >
              <label class="form-check-label">Card Status</label>
                 <div class="form-check">
                      <input type="radio"
                             name="card_status"
                             v-model="card_status"
                             class="form-check-input"
                             value=0
                             @input="set_ab_sc(0)"
                             aria-label="Single Payment, don't save" />
                      <label class="form-check-label"> Single Payment, don't save card</label>
                      <br />
                      <input type="radio"
                             name="card_status" 
                             v-model="card_status"
                             class="form-check-input"
                             value=1
                             @input="set_ab_sc(1)" aria-label="Single Payment, save card" />
                      <label class="form-check-label"> Single Payment, save card</label>
                      <br />
                      <input type="radio"
                             name="card_status" 
                             v-model="card_status"
                             class="form-check-input"
                             value=2
                             @input="set_ab_sc(2)" aria-label="Make a Payment and set up auto-billing" />
                      <label class="form-check-label"> Make a payment and set up auto-billing</label>
                 </div>
        </div>
    </div>
    <div class="form-group row">
              <label class="col-sm-3" for="email">Send receipt to</label>
              <input name="email" type="text" class="form-control col-sm-8" v-model="userData.email"
                        id="email" placeholder="Email Address" aria-label="Email Address" />
    </div>
    <div class="form-group row">
                 <label class="col-sm-3" for="comments">Comments</label>
                 <textarea id="comments" class="form-control col-sm-8" rows=5 v-model="userData.comments">
                 </textarea>
    </div>
    <div class="form-group row">
               <div class="offset-sm-4 col-sm-4">
                    <button class="btn btn-outline-primary"
                            type="submit">Submit</button>
    </div>
               <div class="col-sm-4"></div>
            </div>

   </form>
 </div>
</template>

<script>
   import axios from 'axios';
   import CreditCard from './CreditCard.vue';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import { useVuelidate } from '@vuelidate/core';
   import { required, numeric, helpers } from '@vuelidate/validators';
   import { luhn, valid_mmyy_date, valid_expiration_date, positive_amount } from '@/helpers/utils.js';

   export default {
      name: 'PayForm',
      components: { CreditCard, ErrorMessage },
      setup: () => ( { v$: useVuelidate() } ),
      validations: {
        userData: {
             amount: {  
                 positive_amount: helpers.withMessage('Amount must be > 0', positive_amount),
                 required: helpers.withMessage('Required', required),
                 numeric: helpers.withMessage('Must be a number', numeric)
             }
        },         
        card_status: { required: helpers.withMessage('Required', required) }
      },           
      emits: [ 'made-payment', 'setpage', 'updated-card', 'added-card', 'message' ],
      props: {
          which_card: Number,
          method: Object,
          info: Object
      },
      computed: {
          footer_tag () {
            if (this.operator === this.current_user) {
                       return "Message sent by " + this.operator;
            } else {
                       return "Message sent by " + this.operator + " for " + this.current_user;
            }
          },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      },
      watch: {
          which_card: function (newCard, oldCard) {
               this.userData.save_card = undefined;
               this.userData.auto_bill = undefined;
          },
          info: function (newInfo, oldInfo) {
            this.userData.amount = newInfo.current_due;
          }
       },
      methods: {
         clearProbs () {
              this.problems['amount'] = '';
              this.problems['ccname'] = '';
              this.problems['ccnumber'] = '';
              this.problems['cvv'] = '';
              this.problems['cardtype'] = '';
              this.problems['expdate'] = '';
              this.problems['address'] = '';
              this.problems['city'] = '';
              this.problems['state'] = '';
              this.problems['zipcode'] = '';
              this.problems['country'] = '';
              this.problems['card_status'] = '';
         },
         kickMe () {
              this.cckey++;
         },
         set_ab_sc(value) {
           this.userData.save_card = false;
           this.userData.auto_bill = false;
           if (value > 0) {
              this.userData.save_card = true;
              if (value == 2) {
                 this.userData.auto_bill = true;
              }
           } 
         },
         appendNotice(msg) {
           var body = this.$store.state.status_body + msg;
           this.$store.commit('setStatusBody', body);
         },
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         setContent(item, title, args) {
             this.$emit('setpage', item, title, args);
         },
         sendMsg(send_from, send_to, message, subject) {
            var url = "/api/sendmessage";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.msgData.send_from = send_from;
            this.msgData.send_to = send_to;
            this.msgData.message = message;
            this.msgData.subject = subject;
            this.msgData.footer = this.footer_tag;
            axios.post(url, this.msgData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                   })
         },
         save_this_card () {
            var url = "api/add-card";
            var title = "";
            var body = "";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         }; 
            this.userData.account = this.current_user;
            axios.post(url, this.userData, config )
            .then (
                      response => {
                         if (response.data.status) {
                             this.addCardForm.card_id = response.data.data.card_id;
                             body = "\nThis Card has been added";
                             this.appendNotice(body);
                             this.cckey++;
                             this.$emit('added-card');
                         } else {              
                             title = "Failed";
                             body = response.data.message;
                             this.responseMessage(title, body, 'modal', 'serious', '', []);
                         }
                      }) 
            .catch(error => {
                         var fail_msg = "Unable to add this card " + error;
                         this.responseMessage("Failed", fail_msg, 'modal', "serious", '', []);
                      });
         },
         who_to_message (email) {
            if (email == "") {
               return "No receipt sent";
            } else { 
               return "Receipt sent to " + email;
            }
         },
         sendEmail (trans_id, account, ccn4, amount, client_email, comments) {
// Message 1: Receipt of payment for the client
            var to = client_email;
            var from = "accounting@swcp.com";
            var now = new Date;
            var curr_time = now.toString();
            var who_to = this.who_to_message(client_email);
            var body = 
 `SWCP Receipt for payment
   ----------------------------------------------------------
   We received a payment of $${amount} for account ${account}

   CCN: ....${ccn4}
   Transaction ID: ${trans_id}
   ${who_to}
   Date: ${curr_time}
   Notes: ${comments}
                      `;
            var subject = "SWCP Payment received";
            // Check to see if the user wants a receipt.
            if (client_email != '') {
                this.sendMsg(from, to, body, subject);
            }

// Message 2: Notification to accounting of payment received.
            to = "accounting@swcp.com";
            from = "noc@swcp.com";
            subject = `Payment received for ${account}`;
            this.sendMsg(from, to, body, subject);

         },
         buildThanks (data, form, payrec) {
            var message;
            message = "Thank you for your payment! <br>Your Transaction ID is " + data.trans_id;
            message += "<br>Amount: $" + payrec.amount + "<br>";
            message += this.who_to_message(form.email);
            return message;
         },
         async processNewCard() {
            var url;
            var property, message;
            var title = "";
            var body = "";
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            var account = this.current_user;
            const result = await this.v$.$validate();
            if (this.pending) { console.log("processNewCard: submit while pending"); return; }
            if (! result) {
                this.clearProbs();
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
            } else {
               this.pending = true;
               url = "api/single-payment";
               this.userData.account = account;
               this.addCardForm.account = account;
               axios.post(url, this.userData, config )
               .then (
                       response => {
                           if (response.data.status) {
                                 title = "Successful Payment";
                                 body = this.buildThanks (response.data.data, this.userData, response.data.payment_record);
                                 this.responseMessage(title, body, "thankyou", "info", '', []);
                                 if (this.userData.save_card) {
                                     this.save_this_card();
                                 } 
                                 this.sendEmail(response.data.data.trans_id, this.userData.account, 
                                                this.userData.ccnumber.slice(-4), this.userData.amount, 
                                                this.userData.email, this.userData.comments);
                                 this.v$.$reset();
                                 this.pending = false;
                                 this.$emit('made-payment');
                           } else {
                                 title = "No Payment made";
                                 body = response.data.message;
                                 this.responseMessage(title, body, 'modal', "serious", '', []);
                                 this.pending = false;
                                 console.log("Payment failed");
                                 console.log(response.data);
                           }
                       })
               .catch(error => {
                             title = "API Payment failure";
                             body = "Contact noc@swcp.com " + error;
                             this.responseMessage(title, body, 'modal', "serious", '', []);
                             this.pending = false;
               })
            }
         },

         processPayment({ resetForm }) {
//          New credit card, not in CIM.
            var url;
            var property, message;
            var title = "";
            var body = "";
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            var account = this.current_user;

            if (this.which_card == 0) {
                this.processNewCard();
            } else {
                url = "api/saved-card-payment";
                this.userData.account = account;
                this.userData.which_card = this.which_card;
                this.pending = true;
                axios.post(url, this.userData, config )
                .then (
                       response => {
                              if (response.data.status) {
                                 title = "Successful payment";
                                 body = this.buildThanks (response.data.data, this.userData, response.data.payment_record);
                                 this.sendEmail(response.data.data.trans_id, this.userData.account, 
                                                this.method.ccn4, this.userData.amount, this.userData.email,
                                                this.userData.comments);
                                 this.pending = false;
                                 this.$emit('made-payment');
                                 this.responseMessage(title, body, 'thankyou', "info", '', []);
                       } else {
                                 title = "No Payment made";
                                 body = response.data.message;
                                 this.responseMessage(title, body, 'modal', "serious", '', []);
                                 this.pending = false;
                                 console.log("Payment failed");
                                 console.log(response.data);
                       }
                 })
                .catch(error => {
                       title = "API Payment failure";
                       body = "Contact noc@swcp.com " + error;
                       this.responseMessage(title, body, 'modal', "serious", '', []);
                       this.pending = false;
                 })
            }
         }
      },
      data () {
        return {
           pending: false,
           card_status: '',
           addCardForm: { account: '',
                          card_id: 0
                        },
           msgData: {
               send_to: '',
               send_from: '',
               subject: '',
               message: '',
               footer: ''
           },
           acctData: {
               account: ''
           },
           userData: {
               amount: '',
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: 'US',
               expdate: '',
               cvv: '',
               cardtype: '',
               account: '',
               email: '',
               save_card: '',
               auto_bill: '',
               use_this_card: '',
               save_status: 0,
               comments: ''
           },
           problems: {
               amount: '',
               ccname: '',
               ccnumber: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: '',
               expdate: '',
               cvv: '',
               cardtype: '',
               card_status: ''
           },
           cckey: 1,
           which_method: {},
           errors: []
        }
      }
    }

</script>
