<template>
     <div class="col-sm-12">
          <hr class="boldblue">
          <h5 class="offset-sm-2">Choose a card on file or enter a new card</h5>
          <p class="text-center alert alert-info">If you have a card used for auto-billing it will be blue</p>
          <div id="my-cards" class="offset-sm-2 form-group row">
            <label class="col-2">Select Card</label> 
            <div class="col-8">
              <div class="form-check">
                <input name="which-card" type="radio" class="form-check-input" 
                       @change="onChange($event)"
                       value="0" checked> 
                <label class="form-check-label">New Card</label>
              </div>
              <div v-for="method in info.pay_methods" 
                   :key="method.cid"  
                   class="form-check">
                <input name="which-card" type="radio" class="form-check-input" 
                       @change="onChange($event, method)"
                       :value="method.id" 
                       :checked=0>
                <label :class="ab_style(method.on_auto_billing, method.id)">
                       ...{{ method.ccn4 }} {{ method.card_type }} ( {{ method.nice_expdate }} )</label>
              </div>
            </div>
          </div> 
          <hr class="boldblue">
     </div>

</template>

<script>
   import axios from "axios";
   export default {
      name: 'CardsOnFile',
      emits: [ 'change-card' ],
      data () {
        return {
           use_this_card: 0,  // Card ID
           selected_card: "", // Card label
           msg: "",
           errors: [],
           info: { pay_methods: [] }
        }
      },

      methods: {
         getInfo ( ) {
          var config = { headers:
                          { Authorization: "Bearer " + this.jwt }
                       };
          this.use_this_card = 0;
          axios.get("api/get-payment-methods/" + this.current_user, config )
          .then (
                 response => {
                    this.info = response.data.data;
                 })
          .catch(error => {
                    this.info = [ { 'Status': 'Failed',
                                    'account': this.current_user,
                                    'pay_methods': [],
                                    'error': error } ];
                 })
         },
         onChange (event, method) {
            this.use_this_card = event.target.value;
            if (method === undefined) {
                this.selected_card = "New Card";
            } else {
                this.selected_card = method.ccn4 + " " + method.card_type;
            }
            this.$emit('change-card', this.use_this_card, method);
         },
         ab_style (on_ab, id) {
           var myclass = "form-check-label";
           if (on_ab == 1) { 
              myclass += " ab-cc"; 
              this.use_this_card = id;
           }
           return myclass;
         }
      },
     mounted() {
         this.getInfo();
     },
     computed: {
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
     }

  }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
.ab-cc {
  color: #409eff;
  font-weight: bold;
}
.boldblue {
  border-top: 3px solid rgba(68,118,174,.85);
}

</style>
