<template>
  <div>
  <h2 class="dbhead">Domain Registration or Transfer</h2>
  <VForm  v-on:submit="processReg">
  <div class="col-sm-12">
       <div class="row justify-content-md-center">
                  <ErrorMessage class="form-error" name="domain" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Domain Name</label>
                 <VField class="form-control col-sm-8" type="text" 
                        name="domain" placeholder="Domain Name" aria-label="Domain Name" 
                        v-on:focus="this.valid = false"
                        v-on:blur="this.valid = true"
                        v-model="dgData.domain"
                        rules="required"  />
       </div>
       <div class="row justify-content-md-center">
                  <ErrorMessage class="form-error" name="organization" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Organization</label>
                 <VField class="form-control col-sm-8" type="text" 
                        name="organization" placeholder="Organization" aria-label="Organization" 
                        v-model="dgData.organization"
                        />
       </div>
       <div class="row justify-content-md-center">
                  <ErrorMessage class="form-error" name="contact_email" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Contact Email Address</label>
                 <VField class="form-control col-sm-8" type="text" 
                        name="contact_email" placeholder="Email" aria-label="Email" 
                        v-model="dgData.contact_email"
                        rules="required|email" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Registration Period</label>
                 <TldPrices v-bind:init="tldinit" 
                            v-bind:domain="dgData.domain" 
                            v-bind:valid="valid"
                            v-on:select_num_years="getNumYears" />
       </div>
       <div class="row justify-content-md-center">
                  <ErrorMessage class="form-error" name="whois_priv" />
       </div>
       <div class="form-group row">
                 <label class="col-sm-4">Would you like Whois Privacy?</label>
                 <div class="form-check form-check-inline col-sm-5">
                      <VField class="form-check-input" type="radio" 
                             name="whois_priv" 
                             v-model="dgData.whois_priv"
                             value="No" aria-label="No" rules="required" />
                      <label class="form-check-label" >No &nbsp;&nbsp;</label>
                      <VField class="form-check-input" type="radio" 
                             v-model="dgData.whois_priv"
                             name="whois_priv" value="Yes" aria-label="Yes" />
                      <label class="form-check-label">Yes ($5/yr) </label>
                </div>
       </div>
       <div class="form-group row">
            <label class="col-sm-4">
                   <router-link v-bind:to="{ name: 'domain-registration-help' }">Type</router-link></label>
            <div class="form-check form-check-inline col-sm-5">
                <VField name="reg_type" as="select" v-model="dgData.reg_type">
                    <option v-for="(reg_type, index) in reg_types"
                            v-bind:key="index"
                            v-bind:value="reg_type"> {{ reg_type }} </option>
                </VField>
            </div>
       </div>
       <div class="form-group row">
            <label class="col-sm-4">Website Options</label>
            <div class="form-check form-check-inline col-sm-5">
                <VField name="website_options" as="select" v-model="dgData.website_option">
                    <option v-for="(website_option, index) in website_options"
                            v-bind:key="index"
                            v-bind:value="website_option"> {{ website_option }} </option>
                </VField>
            </div>
       </div>
       <div class="form-group row">
           <p>Please include any special instructions below:<br></p>
           <textarea class="form-control" name="instructions" v-model="dgData.instructions">
           </textarea>
       </div>
       <div class="row">
         <p>
             You must agree to the OpenSRS Registration Agreement to continue. Please note the following:
         </p>
         <div class="oldstyle">
            <ul>
                <li>Tucows/OpenSRS is Southwest Cyberport's domain registration partner.</li>
                <li>Each top-level domain (TLD, such as .com, .org, .doctor, .co.uk, etc.) 
                is governed by both ICANN and the registry which owns that TLD. </li>
                <li>The Registration Agreement includes by reference the policies and procedures and requirements of ICANN and all of the domain registries.</li>
                <li>The Registration Agreement names Tucows/OpenSRS as the Designated Agent for your domain. This allows Tucows 
                to automatically approve of Registrant changes on your behalf instead of requiring a back-and-forth 
                email approval process for every registrant change.</li>
                <li>You must agree to the Registration Agreement in order for SWCP to register or transfer a domain on your behalf.</li>
            </ul>
             <div class="row justify-content-md-center">
                <button type="button" class="btn btn-info" @click="showModal">
                      Read the Registration Agreement
                </button>
             </div>
         </div>
       </div>
       <div class="row mt-3">
          <div class="alert alert-info">
              <h4>Special rules apply to .app, .dev, and .page domains</h4>
              .APP, .DEV and .PAGE domains are secure domains that require HTTPS for all websites of these TLD types.
              You can buy your .APP, .DEV or .PAGE domain name now, but for it to work correctly in any browser, you must first configure HTTPS serving.
              For more information about setting up HTTPS, including resources to obtain an SSL certificate, please visit SWCP's SSL Certificates
          </div>
        </div>
  </div>
  <div class="form-group row">
        <label class="col-sm-2">Please Choose.</label>
        <div class="form-check col-sm-8">
             <VField class="form-check-input" type="radio" 
                             name="terms" 
                             v-model="dgData.terms"
                             value="DontAgree" aria-label="DontAgree"/>
             <label class="form-check-label" >No, I don't agree.</label><br>
             <VField class="form-check-input" type="radio" 
                             v-model="dgData.terms"
                             name="terms" value="Agree" aria-label="Agree" />
             <label class="form-check-label">Yes, I have read the Registration Agreement and agree to all terms.</label>
        </div>
  </div>
  <div class="form-group row justify-content-md-center">
       <button class="btn btn-outline-info align-center"
               type="submit">Register Domain</button>
  </div>
  </VForm>
   <Modal v-model:body="bodytext" v-show="isModalVisible"
      title="OpenSRS Agreement"
      footer=""
      @close="closeModal" />

  </div>
</template>

<script>
   import axios from "axios";
   import dayjs from "dayjs";
   import { Form as VForm, Field as VField, ErrorMessage } from "vee-validate";
   import TldPrices from '@/components/TldPrices';
   import Modal from '@/components/Modal';
   export default {
      name: 'DomReg',
      components: { VForm, VField, ErrorMessage, TldPrices, Modal },
      emits: [ 'message' ],
      mounted() {
          this.bodytext = this.getAgreement();
          this.date = dayjs().format('MMM D, YYYY h:mm:ss A');
      },
      methods: {
         closeModal () { this.isModalVisible = false; },
         showModal ()  { this.isModalVisible = true; },
         responseMessage(title, body, type, alert, footer, menu) {
                 var args = { title: title, body: body,
                              type: type, alert: alert,
                              footer: footer, menu: menu };
                 this.$store.commit('setStatus', args);
                 this.$emit('message');
                 return;
         },
         getAgreement () {
            var url = "/api/gettext";
            var config = { };
            axios.post(url, this.textData, config )
             .then (
                     response => {
                           this.bodytext = response.data.data;
                     })
             .catch(error => {
                     var notes = "";
             })
         },
         sign_agreement() {
            var url = "/api/signagreement";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.signData.domain = this.dgData.domain;
            axios.post(url, this.signData, config )
             .then (
                     response => {
                           this.bodytext = response.data.data;
                     })
             .catch(error => {
                      console.log("Failed");
                      console.log(error);
                      this.responseMessage('Signature Failed', error, 'modal', 'serious', '', []);
             })
         },
         send_message( msg ) {
            var url = "/api/sendmessage";
            var success = "Your domain registration has been submitted. <br>If you have any questions you can reach us at 505-232-7992 or help@swcp.com.";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.send_from = this.customer_email;
            this.sendData.message = msg;
            this.sendData.subject = "Domain registration " + this.dgData.domain;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      if (this.response_status) {
                          this.responseMessage('Domain Registration Submitted', success, 'thankyou', 
                                               'info', '', [ {name: 'Register another domain', 'url': 'domain-registration'}] );
                      } else {
                          this.responseMessage('Error sending registration', response.data.message, 'modal', 'serious', '', []);
                      }
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage('Problems with Registration', error, 'modal', 'serious', '', []);
                   })

         },
         processReg() {
            // window.scrollTo(0, 0);
            if (this.dgData.terms == "DontAgree") {
                alert("We can't register your domain unless you agree to the terms");
            } else {
                if (this.dgData.numyears == '') {
                    alert("Please select number of years for the registration");
                } else {
                    this.send_message(this.constructMessage());
                    this.sign_agreement();
                }
            }
         },
         constructMessage() {
           var totalprice = this.dgData.price;
           if (this.dgData.whois_priv == "Yes") { totalprice += 5; }

           var subject  = "Domain registration request for " + this.current_user;
           var from  = this.customer_email;
           var message  = "We have received a web-authenticated " + 
                          "request from account " + this.current_user + "\n" +
                          "to register/transfer a domain:\n";
           message += "----------------------------------------------------------------------\n";

           message += "Domain:           " + this.dgData.domain + "\n";
           message += "Type:             " + this.dgData.reg_type + "\n";
           message += "Logname:          " + this.current_user + "\n";
           message += "Organization:     " + this.dgData.organization + "\n";
           message += "Email Contact:    " + this.dgData.contact_email + "\n";
           var ess = (this.dgData.numyears > 1) ? "s" : "";
           message += "Reg Period:       " + this.dgData.numyears + " Year" + ess + "\n";
           message += "WHOIS Privacy:    " + this.dgData.whois_priv + "\n";
           message += "Website Options:  " + this.dgData.website_option + "\n";
           message += "For-Profit:       " + this.dgData.profit_nonprofit + "\n";
           message += "\n";
           message += "Registration Agreement Accepted:  Yes\n";

           message += "\nEstimated Total Domain Registration Cost: $" + totalprice + "\n";
           if (this.dgData.website_option != "NONE") {
               message += "(Additional fees may apply for web hosting options)\n";
           }

           message += "\n";
           message += "---Notes--------------------------------------------------------------\n";
           message += this.dgData.instructions;
           message += "\n";
           message += "----------------------------------------------------------------------\n";
           message += "\n";
           message += "Submission Timestamp: " + this.date;
           return message;
         },

         getNumYears(numyears, price) {
            this.dgData.numyears = numyears;
            this.dgData.price = price;
         }
      },
      computed: {
          footer_tag () {
              if (this.operator === this.current_user) {
                 return "Message sent by " + this.operator;
              } else {
                 return "Message sent by " + this.operator + " for " + this.current_user;
              }
          },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      },
      data () {
        return {
           isModalVisible: false,
           date: '',
           bodytext: '',
           signData: {
               domain: ''
           },
           sendData: {
               send_from: '',
               send_to: 'help@swcp.com,hostmaster@swcp.com',
               footer: '',
               message: ''
           },
           textData: {
               text: 'opensrs-agreement.html'
           },
           tldinit: "Number of years to register",
           valid: false,
           reg_types: [ "New Domain",
                        "SWCP/OpenSRS will be Registrar Only",
                        "SWCP will be DNS Host Only",
                        "SWCP will be Registrar & DNS Host",
                        "SWCP will be Web/Email Host Only"],
           website_options: [ "NONE", 
                              "Basic Webhosting",
                              "Pro Webhosting",
                              "Alias for another site" ],
           dgData: {
              domain: '',
              organization: '',
              contact_email: '',
              numyears: '',
              price: '',
              reg_type: 'New Domain',
              whois_priv: 'No',
              website_option: 'NONE',
              profit_nonprofit: 'profit',
              instructions: '',
              terms: 'DontAgree' 
           }
        }
      }
   }

</script>

<style>
::placeholder { 
  color: #a2bbd3 !important;
}
div.oldstyle ul {
    list-style-type: square !important;
    padding-left: 1em;
}
.oldstyle ul>li {
    display: list-item;
}
</style>
