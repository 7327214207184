<template>
   <div>
     <select class='tlddd' v-on:change="getNumYears">
        <option value=-1 disabled selected>{{ init }} {{ tldmark }}</option>
        <option v-for="(price, index) in prices" 
                v-bind:key="index"
                v-bind:value="index"> {{ price.item }} (${{price.baseprice}}/year)</option>
     </select>
     <span v-if="err != ''" class="alert alert-danger">{{ err }} </span>
  </div>
</template>

<script>

import axios from "axios";

export default {
      name: "TldPrices",
      emits: [ 'select_num_years' ],
      props: {
          init: String,
          valid: Boolean,
          domain: String
      },
      data () {
        return {
          tld: '',
          err: '',
          numyears: 0,
          price: 0,
          prices: []
        }
      },
      watch: {
          valid: function (newVal, oldVal) {
                   if (this.valid) {
                      this.tld = this.getTld(this.domain);
                      this.prices = this.getPrices(this.tld);  
                   }
                 }
      },
      mounted() {
          this.tld = this.getTld(this.domain);
          this.prices = this.getPrices(this.tld);
      },
      methods: {
         getNumYears(event) {
             var index = event.target.value;
             this.price = this.prices[index].price;
             this.numyears = this.prices[index].id;
             this.$emit('select_num_years', this.numyears, this.price);
         },
         getTld(domain) {
            var tld = "";
            var rest;
            if (domain) {
               rest = domain.split(".");
               rest.shift();
               tld = rest.join(".");
            }
            return tld;
         },
         getPrices(tld) {
            var url = "/api/tldpricelist/";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            axios.get(url + tld, config )
              .then (
                 response => {
                    this.prices = response.data.data;
                    this.err = "";
                    if (! this.prices.length) { this.err = "Can't find it"; }
                 })
              .catch(error => {
                    console.log("Didn't find " + tld);
                    if (tld != "") {
                       this.err = tld + " isn't a TLD that we can register";
                    }
                    this.prices = [ ];
               })
         }
      },
      computed: {
          tldmark() {
              if (this.tld == "") { return ""; }
              return "[ ." + this.tld + " ]";
          },
          currentRouteName() { return this.$route.name; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          jwt ()             { return this.$store.state.jwt; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tlddd {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
</style>
