<template>
  <div :class="div_class">
       <div class="row">
           <div class="col-4 my-3">
             <h3>{{ title }}</h3>
           </div>
           <div class="col-4 my-3">
             <button type="button" class="btn btn-outline-primary" v-on:click="prefillBillAddr()">Prefill Billing Info
              for <span class="swcp-look-at-me">{{ current_user }}</span>
             </button> &nbsp;&nbsp;
           </div>
           <div class="col-4 my-3">
             <button type="button" class="btn btn-outline-primary" v-on:click="clearForm()">Clear CC Info</button>
           </div>
       </div>
       <div class="row justify-content-md-center">
          <div class="col-9 offset-3">
            <ErrorMessage :error="problems.ccname" />
          </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Card Holder Name</label>
                 <input type="text" class="form-control col-sm-8" 
                        name="ccname" placeholder="Card Holder Name" aria-label="Card Holder Name" 
                        :value="ccname"
                        @input="$emit('update:ccname', $event.target.value)" />
       </div>
       <div class="row justify-content-md-center">
          <div class="col-9 offset-3">
            <ErrorMessage :error="problems.ccnumber" />
          </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Card Number</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="ccnumber" placeholder="Card Number" aria-label="Card Number" 
                        :value="ccnumber"
                        @input="$emit('update:ccnumber', $event.target.value)" />
       </div>
       <div class="row justify-content-md-center">
              <div class="offset-sm-2 col-sm-3 justify-content-sm-center">
                   <ErrorMessage :error="problems.expdate" />
              </div>
              <div class="offset-sm-3 col-sm-2 justify-content-sm-center">
                   <ErrorMessage :error="problems.cvv" />
              </div>
       </div>

       <div class="form-group row">
                 <label class="col-sm-3">Expiration Date (MMYY)</label>
                 <input class="form-control col-sm-3" type="text" 
                               name="expdate"
                               :value="expdate"
                               @input="$emit('update:expdate', $event.target.value)"
                               placeholder="MMYY" aria-label="Expiration Date MMYY" /> 
                 <label class="col-sm-3">Security Code (CVV)</label>
                 <input class="form-control col-sm-2" type="text" 
                         name = "cvv" :value="cvv" placeholder="CVV" aria-label="Security code (CVV)" 
                         @input="$emit('update:cvv', $event.target.value)" />
       </div>
       <div class="row justify-content-md-center">
          <div class="col-9 offset-3">
                   <ErrorMessage :error="problems.cardtype" />
           </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Card Type</label>
                 <div class="form-check form-check-inline col-sm-7">
                      <input class="form-check-input" type="radio" 
                             name="cardtype" 
                             @input="$emit('update:cardtype', $event.target.value)"
                             v-model="internal_card_type"
                             value="Visa" aria-label="Visa" />
                      <label class="form-check-label">Visa &nbsp;&nbsp;</label>
                      <input class="form-check-input" type="radio" 
                             @input="$emit('update:cardtype', $event.target.value)"
                             v-model="internal_card_type"
                             name="cardtype" value="M/C" aria-label="MasterCard" />
                      <label class="form-check-label">MasterCard  &nbsp;&nbsp; </label>
                      <input class="form-check-input" type="radio"
                             @input="$emit('update:cardtype', $event.target.value)"
                             v-model="internal_card_type"
                             name="cardtype" value="Discover" aria-label="Discover" />
                      <label class="form-check-label">Discover  &nbsp;&nbsp; </label>
                      <input class="form-check-input" type="radio" 
                             @input="$emit('update:cardtype', $event.target.value)"
                             v-model="internal_card_type"
                             name="cardtype" value="AmExp" aria-label="American Express" />
                      <label class="form-check-label">AmExp  </label>
                </div>
       </div>
       <div class="row justify-content-md-center">
          <div class="col-9 offset-3">
                   <ErrorMessage :error="problems.address" />
           </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">Card Billing Address</label>
                 <input class="form-control col-sm-8" type="text" 
                        name="address" placeholder="Address" aria-label="Address" 
                        :value="address"
                        @input="$emit('update:address', $event.target.value)" />
       </div>
       <div class="row">
              <div class="offset-sm-3 col-sm-4 justify-content-md-center">
                   <ErrorMessage :error="problems.city" />
              </div>
              <div class="col-sm-1 justify-content-md-center">
                   <ErrorMessage :error="problems.state" />
              </div>
               <div class="col-sm-2">
                   <ErrorMessage :error="problems.zipcode" />
               </div>
       </div>
       <div class="form-group row">
                 <label class="col-sm-3">&nbsp;</label>
                 <input class="form-control col-sm-4" type="text"
                        @input="$emit('update:city', $event.target.value)"
                        :value="city"
                        name="city" placeholder="City" aria-label="City" />
                 <input class="form-control col-sm-1" type="text"
                        @input="$emit('update:state', $event.target.value)"
                        :value="state"
                        name="state" placeholder="State" aria-label="State" />
                 <input class="form-control col-sm-2" type="text" 
                        @input="$emit('update:zipcode', $event.target.value)"
                        :value="zipcode"
                        name="zipcode" placeholder="Zipcode" aria-label="Zipcode" />
                 <input class="form-control col-sm-1" type="text" :value="country"
                        @input="$emit('update:country', $event.target.value)"
                        name="country" placeholder="Country" aria-label="Country" />
       </div>
  </div>
</template>

<script>
   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core';
   import { required, numeric, helpers } from '@vuelidate/validators';
   import { luhn, valid_mmyy_date, valid_expiration_date, positive_amount } from '@/helpers/utils.js';
   import ErrorMessage from '@/components/ErrorMessage.vue';

   export default {
      name: 'CreditCard',
      components: { ErrorMessage },
      emits: [ 'update:ccname', 'update:ccnumber', 'update:address', 'update:city', 
               'update:state', 'update:zipcode', 'update:country', 'update:expdate', 
               'update:cvv', 'update:cardtype', 'kickit', 'clear-probs' ],
      props: {
        title: String,
        ccname: String,
        ccnumber: String,
        address: String,
        city: String,
        state: String,
        zipcode: String,
        country: String,
        cardtype: String,
        expdate: String,
        cvv: String,
        problems: Object
      },
      setup () {
        return { v$: useVuelidate() }
      },
      validations: {
        ccname: { required: helpers.withMessage('Required', required) },
        ccnumber: { luhn: helpers.withMessage('Invalid credit card', luhn), 
                    required: helpers.withMessage('Required', required) },
        cardtype: { required: helpers.withMessage('Required', required) },
        address: { required: helpers.withMessage('Required', required) },
        city: { required: helpers.withMessage('Required', required) },
        state: { required: helpers.withMessage("Req'd", required) },
        zipcode: { required: helpers.withMessage('Required', required) },
        country: { required: helpers.withMessage('Required', required) },
        expdate: { required: helpers.withMessage('Required', required),
                   valid_expiration_date: helpers.withMessage('Invalid Expiration', valid_expiration_date) },
        cvv: { required: helpers.withMessage('Required', required),
               numeric: helpers.withMessage('Invalid', numeric) }
      },
      methods: {
         clearForm() {
           this.internal_card_type = "";
           this.$emit('update:ccname', "");
           this.$emit('update:ccnumber', "");
           this.$emit('update:cardtype', "");
           this.$emit('update:cvv', "");
           this.$emit('update:expdate', "");
           this.$emit('update:address', "");
           this.$emit('update:city', "");
           this.$emit('update:state', "");
           this.$emit('update:zipcode', "");
           this.$emit('update:country', "");
           this.$emit('clear-probs');
           this.$emit('kickit');
         },
         prefillBillAddr() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var account = this.current_user;
            var url="api/get-prefill-cc-addr";
            this.acctData.account = account;
            axios.post(url, this.acctData, config )
            .then (
                 response => {
                    var addr = response.data.data.address;
                    this.$emit('update:ccname', addr.first_name + " " + addr.last_name);
                    this.$emit('update:address', addr.address);
                    this.$emit('update:city', addr.city);
                    this.$emit('update:state', addr.state);
                    this.$emit('update:zipcode', addr.zipcode);
                    if (addr.country == "") {
                        this.$emit('update:country', "US");
                    } else {
                        this.$emit('update:country', addr.country);
                    }
                    this.$emit('kickit');
                 })
            .catch(error => {
                   if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                   }
            })
         } 
      },
      mounted () {
         this.internal_card_type = this.cardtype;
      },
      computed: {  
         user () { return this.$store.state.user; },
         current_user () { return this.$store.state.current_user; },
         jwt () { return this.$store.state.jwt; },
         mode () { return this.$store.state.mode; },
         div_class () { return "col-sm-12 grayline-" + this.mode; }
      },     
      data () {
         return {
           internal_card_type: '',
           acctData: {
               account: ''
           }
         }
      }
   }

</script>

<style scoped>
::placeholder { 
  color: #a2bbd3 !important;
}
.grayline-dark {
  border: 1px solid #555555;
  background-color: #3e444a;
}
.grayline-light {
  border: 1px solid #dddddd;
  background-color: #f8f8f8;
}
</style>
