<template>
   <div class="row">
    <h2 class='dbhead'>Email Aliases for <span class='swcp-em'>{{current_domain}}</span></h2>
   </div>
   <div class="row">
     <div class="col-6 justify-content-center">
        <select class='email-select mb-5' v-model="current_domain" v-on:change="selectNewDomain">
           <option value='' disabled>Select Domain</option>
           <option v-for="domain in domains"
                   v-bind:key="domain.domain"
                   v-bind:value="domain.domain"> {{ domain.domain }} ({{domain.num_aliases}})</option>
        </select>
     </div>
      
     <div class="col-6 justify-content-center">
        <button v-if="current_domain != ''" v-on:click="add_alias()" class="btn btn-outline-info form-control">Add an Alias</button>
     </div>
   </div>
   <div class="row">
     <div class="col-12">
        <vue-good-table class="mb-3" v-if="current_domain !=''"
          :columns="columns"
          :rows="rows"
          :theme="vgt_theme"
          @on-cell-click="selectAlias"
          :sort-options="{
              enabled: true,
              initialSortBy: {field: 'src', type: 'asc'}
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'top',
            perPageDropdown: [5, 10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
          }"
          :search-options="{
             enabled: true,
             placeholder: 'Search All Fields'
           }"
           styleClass="vgt-table bordered condensed"
         >
       </vue-good-table>
     </div>
   </div>

</template>

<script>
import axios from "axios";
import VueGoodTable from '@/components/vue3-good-table/Table';
export default {
    name: 'EmailAliases',
    components: { VueGoodTable },
    emits: [ 'message' ],
    mounted () {
        this.current_domain = "";
        this.getDomainList();
        this.getRouteParm();
    },
      watch: {
          current_user: function (newUser, oldUser) {
            console.log("Changing user " + newUser);
            this.current_domain = "";
            this.$store.commit('setSelectedDomain', '');
            this.getDomainList();
          }
      },
    methods: {
       add_alias () {
          this.$router.push({ name: 'create-email-alias', params: { src_domain: this.current_domain }});
       },
       pencil_in (list) {
          var value = list[0];
          var count = list.length;
          var rest = count - 1;
          var plural = rest <= 1?"":"s";
          if (count > 1) { value += " [ " + rest + " more target" + plural + " ]"; }
          return value;
       },
       full_email (src, domain) {
          return src + "@" + domain;
       },
       responseMessage(title, body, type, alert, footer, menu) {
              var args = { title: title, body: body,
                           type: type, alert: alert,
                           footer: footer, menu: menu };
              this.$store.commit('setStatus', args);
              console.log("EmailAliases: responseMessage");
              console.log(args);
              this.$emit('message');
              return;
       },
       selectAlias(params) {
           var row = params.row;
           this.selected_row = row;
           this.selected_cell = params.column;
           if (this.selected_row.src == "*") {
               alert("You can't change a catch-all alias");
               return;
           }
           if (this.selected_cell.field == "edbtn") {
               this.$router.push({ name: 'update-email-alias', params: { domain: this.current_domain, src: row.src } });
           }
           if (this.selected_cell.field == "delbtn") {
               this.deleteAlias(this.selected_row.src, this.current_domain);
           }
       },
       deleteAlias(src, domain) {
           var url = "/api/delete-domain-alias";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.delData.src_user = src;
           this.delData.src_domain = domain;
           axios.post(url, this.delData, config)
               .then (
                  response => {
                      if (response.data.status) {
                          this.responseMessage('Deletions', response.data.message, 'review', 'info', '', []);
                          this.getDomainList();
                          this.getAliases();
                      } else {
                          this.responseMessage('Failed', response.data.message, 'modal', 'serious', '', []);
                      }
                  })
               .catch(error => {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                      this.responseMessage('Failed', error, 'modal', 'serious', '', []);
               })

       },
       selectNewDomain() {
            this.responseMessage('', '', 'reset', 'info', '', []);
            this.$store.commit('setSelectedDomain', this.current_domain);
            this.getAliases();
       },
       getRouteParm () {
           var domain = this.$route.params.domain;
           this.current_domain = domain;
           if (domain != undefined && domain != "") {
               this.getAliases();
           }
       },
       getAliases() {
           var domain = this.current_domain;
           var url = "/api/domain-email-aliases/" + domain;
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
           this.rows = [];
           axios.get(url, config)
               .then (
                  response => {
                      this.rows = this.append_buttons(response.data.data);
                  })
               .catch(error => {
                  console.log("Failed to find aliases for " + domain + " " + error);
                  this.rows = [];
               })
       },
       append_buttons(aliases) {
           aliases.forEach(alias => {
              if (alias.src == "*") {
                  alias.delbtn = "";
                  alias.edbtn = "";
              } else {
                  alias.delbtn = "<button class='btn btn-sm btn-info'>Delete</button>";
                  alias.edbtn = "<button class='btn btn-sm btn-info'>Edit</button>";
              }
           });
           return aliases;
       },
       getDomainList() {
           var url = "/api/domain-counts/";
           var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                        };
           axios.get(url + this.current_user, config )
              .then (
                 response => {
                    this.domains = response.data.data;
                    this.err = "";
                    if (! this.domains.length) { 
                       this.err = "No Domains found"; 
                       this.$store.commit('setSelectedDomain', '');
                    } else {
                       if (this.selected_domain == "") {
                           this.$store.commit('setSelectedDomain', this.domains[0].domain);
                       }
                       this.current_domain = this.selected_domain;
                       this.selectNewDomain();
                    }
                 })
              .catch(error => {
                    console.log("Didn't find domains for " + this.current_user);
                    this.domains = [ ];
               })

       }
    },
    computed: {
          currentRouteName() { return this.$route.name; },
          selected_domain()  { return this.$store.state.selected_domain; },
          operator ()        { return this.$store.state.operator; },
          current_user ()    { return this.$store.state.current_user; },
          mode()             { return this.$store.state.mode; },
          jwt ()             { return this.$store.state.jwt; },
          vgt_theme()        {
              if (this.mode == "light") {
                 return "swcptab";
              } else {
                 return "swcpdark";
              }
          }
   },
   data () {
      return {
        current_domain: '',
        selected_row: [],
        selected_cell: '',
        delData: {
            src_user: '',
            src_domain: ''
        },
        columns: [
             {
               field: "src",
               label: "Source",
               sortable: true
             },
             {
               field: "dst_list",
               label: "Dest List",
               type: "string",
               sortable: true,
               formatFn: this.pencil_in
             },
             {
               field: "edbtn",
               label: "Edit",
               sortable: false,
               globalSearchDisabled: true,
               width: "10%",
               html: true
             },
             {
               field: "delbtn",
               label: "Delete",
               sortable: false,
               globalSearchDisabled: true,
               width: "10%",
               html: true
             }
        ],
        domains: [],
        rows: [ { src: '', dst_list: [] } ]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.email-select {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}

</style>
