export const luhn = function(ccn) {
   let i, chksum, sum, which, digit, result;
   if (typeof(ccn) !== 'string') { return false; }
   if (/[^0-9-\s]+/.test(ccn)) { return false; }
   sum = 0;
   which = true;
   ccn = ccn.replace(/\D/g, "");
   chksum = parseInt(ccn.charAt(ccn.length - 1));
        
   for (i = ccn.length - 2; i >= 0; i--) {
       digit = parseInt(ccn.charAt(i));
       if (which) {
           digit *= 2;
           if (digit > 9) { digit -= 9; }
           which = false;
       } else { which = true; }
       sum += digit;
   }
   result = ( (sum + chksum) % 10 == 0);
   return result;
}

export const not_negative = function(value) {
    let num = parseFloat(value).toFixed(2);
    if (num < 0) { return false; }
    return true;
}

export const positive_amount = function(value) {
    let num = parseFloat(value).toFixed(2);
    if (num <= 0) { return false; }
    return true;
}

export const valid_mmyy_date = function(value) {
    let mm, yy;
    let cvalue = value.replace('/', '');
    if (cvalue.length != 4) {
       return false;
    }
    mm = cvalue.substr(0,2);
    if (mm < 1 || mm > 12) { return false; }
    return true;
}

export const valid_expiration_date = function(value) {
    let mm, yy, now, now_mm, now_yy;
    if (value == "") { return true; }
    let cvalue = value.replace('/', '');
    if (typeof(cvalue) === 'string') {
       if (cvalue.length != 4) {
           return false;
       } else {
          mm = cvalue.substr(0,2);
          yy = cvalue.substr(2,2);
          if (mm < 1 || mm > 12) { return false; }
          now = new Date(); 
          now_mm = now.getMonth() + 1;
          now_yy = now.getYear() % 100;
          if (yy > now_yy) { return true; }
          if (yy < now_yy) { return false; }
          if (mm >= now_mm) { return true; }
          return false;
       }
    } else {
       return false;
    }
}

export const flattenAddress = function( address ) {
    let addr = "Street:     " + address['address'] + "\n";
    console.log("flattenAddress in utils");
    console.log(address);
    addr     += "City,State zip: " + address['city'] + ", " + address['state'] + " " + address['zipcode'];
    if (address['country'] != "US" && address['country'] != "United States") {
        addr += " " . address['country'];
    }   
    addr    += "\n";
    return addr;
}

