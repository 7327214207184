<template>
<div class="h-100" id="applet">
  <div v-if="! logged_in" class="container-fluid full-width swcp-main" >
     <div class="row">
        <Initial></Initial>
     </div>
     <div class="row">
       <div class="col-sm-2">
       </div>
       <div v-if="allowable" class="col-sm-8">
          <router-view @message="onMessage"></router-view>
       </div>
       <div v-else class="col-sm-8">
           <Login @message="onMessage"/>
       </div>
     </div>
  </div>
  <div v-if="logged_in">
     <dashnav 
           v-bind:db_url="db_url"
           v-bind:aud_url="aud_url"
           v-on:logout="onLogout"
           v-on:change_mode="onChangeMode"
           v-on:setpage="setPage"
           v-on:setCurrentUser="setCurrentUser"
           v-on:userNotFound="userNotFound"></dashnav>

     <div class='slipped_by_75'>
          <Initial></Initial>
     </div>

     <div class="container-fluid swcp-main">
        <div class="row">
          <div class="col-sm-3">
            <sidebar @setpage="setPage"></sidebar>
          </div>
          <div class="content-col col-sm-9">
             <Notices v-if="isNoticeVisible" @notice-closed="closeNotice"></Notices>
             <router-view @message="onMessage"></router-view>
          </div>
        </div>
     </div>
     <div class="swcp-slice fixed-bottom"></div>
   </div>

  <NoticeModal v-show="isModalVisible"
         v-bind:body="modal_bodytext" 
         v-bind:title="modal_title"
         v-bind:alert="modal_alert"
         v-bind:size="modal_size"
         v-bind:footer="modal_footer"
         @modal-notice-close="closeModal" />
</div>

</template>

<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios"
import NoticeModal from '@/components/NoticeModal.vue'
import ThankYou from '@/views/ThankYou.vue'
import Initial from '@/components/Initial.vue'
import Notices from '@/components/Notices.vue'
import Dashnav from '@/components/Dashnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import Login from '@/components/Login.vue'

// messageTypes: review, thankyou, modal
export default {
  name: 'App',
  components: { Dashnav, Login, Sidebar, Initial, Notices, NoticeModal },
  data () {
     return {
       mode_updated: true,
       userData: {
           'logname': '',
           'mode': '',
       },
       mode_colors: {
           'dark':
               { app_altcolor: '#2c3e50',
                 app_color: 'white',
                 app_bgcolor: '#343A40',
                 app_header: 'dodgerblue',
                 app_link: '#ffffdd',
                 btn_link: '#98f0ed',
                 btn_link_hover: "hotpink",
                 form_text: 'white',
                 form_back: '#555555',
                 blue_bgcolor: '#076890',
                 blue_text: '#ffffdd',
                 banner_pic: '@/assets/img/yellow-8167591_1280-banner.jpg'
               },
          'light': 
               {
                 app_altcolor: '#2c3e50',
                 app_color: 'black',
                 app_bgcolor: 'white',
                 app_header: 'dodgerblue',
                 app_link: '#007bff',
                 btn_link: '#007bff',
                 btn_link_hover: "#1E5BA3",
                 form_text: 'black',
                 form_back: 'white',
                 blue_bgcolor: '#aae6ff',
                 blue_text: 'black',
                 banner_pic: '@/assets/img/triangles-banner.png'
               }
       },
       banner_pic: '@/assets/img/triangles-banner.png',
       app_altcolor: '#2c3e50',
       app_color: 'black',
       app_bgcolor: 'white',
       app_header: 'dodgerblue',
       app_link: '#007bff',
       btn_link: '#007bff',
       btn_link_hover: '#1E5BA3',
       form_text: 'black',
       form_back: 'white',
       blue_bgcolor: '#aae6ff',
       blue_text: 'black',

       modal_bodytext: 'Content',
       modal_title: 'Pink or Blue Cat',
       modal_alert: 'info',
       modal_size: 'short',
       modal_footer: '',
       isModalVisible: false,
       isNoticeVisible: false,
       messageType: 'review', 
       db_url: null,
       aud_url: null,
       errors: null,
       args: { }
     }
  },
  computed: {
    currentRouteName() { return this.$route.name; },
    allowable () {
       var allowed_list = [ "login", "reset-panel-password", "reset-email-password",
                            "thankyou", "request-panel-reset", "request-email-reset", 
                            "quickref" ];
       if (allowed_list.includes(this.currentRouteName)) {
           return true;
       } else {
           return false;
       }
    },
    jwt ()          { return this.$store.state.jwt; },
    currentPage ()  { return this.$store.state.currentPage; },
    content_args () { return this.$store.state.content_args; },
    logged_in ()    { return this.$store.state.logged_in; }
  },
    created(){
    var urls = "/api/urls";
    axios.get(urls).then((res) => {
        this.db_url = res.data.data.db_url;
        this.aud_url = res.data.data.aud_url;
    }).catch((err) => {
        this.errors = err;
    });
  },
  mounted() {
     document.title = 'SWCP Member Portal';
     if (localStorage.getItem('id_token')) {
       var jwt = localStorage.getItem('id_token');
       var m = localStorage.getItem('mode');
       if (!m) { m = 'light'; }
       console.log("Mounting App " + m);
       this.$store.dispatch('rehydrate', jwt );
       m = localStorage.getItem('mode');
       console.log("Rehydrated App " + m);
       if (!m) { m = 'light'; }
       this.set_mode(m);
     }
  },
  watch:{
    $route (to, from){
        this.isNoticeVisible = false;
    }
  }, 
  methods: {
    set_mode(mode) {
       this.$store.commit('setMode', mode);
       this.app_altcolor = this.mode_colors[mode]['app_altcolor'];
       this.app_color = this.mode_colors[mode]['app_color'];
       this.app_bgcolor = this.mode_colors[mode]['app_bgcolor'];
       this.app_header = this.mode_colors[mode]['app_header'];
       this.app_link = this.mode_colors[mode]['app_link'];
       this.btn_link = this.mode_colors[mode]['btn_link'];
       this.btn_link_hover = this.mode_colors[mode]['btn_link_hover'];
       this.form_text = this.mode_colors[mode]['form_text'];
       this.form_back = this.mode_colors[mode]['form_back'];
       this.blue_bgcolor = this.mode_colors[mode]['blue_bgcolor'];
       this.blue_text = this.mode_colors[mode]['blue_text'];
       this.banner_pic = this.mode_colors[mode]['banner_pic'];
    },
    closeNotice () {
       this.isNoticeVisible = false;
    },
    closeModal () {
       this.isModalVisible = false;
    },
    onMessage () {
       this.message_type = this.$store.state.message_type;
       //console.log("onMessage in App:" + this.message_type);
       if (this.message_type == "modal") {
           this.modal_bodytext = this.$store.state.status_body;
           this.modal_size = this.$store.state.status_size;
           this.modal_title = this.$store.state.status_title;
           this.modal_alert = this.$store.state.status_alert;
           this.modal_footer = this.$store.state.status_footer;
           this.isNoticeVisible = false;
           this.isModalVisible = true;
       } else {
          if (this.message_type == "review") {
              this.isNoticeVisible = true;
              window.scrollTo(0, 0);
          } else {
              if (this.message_type == "reset") {
                  this.isNoticeVisible = false;
                  this.isModalVisible = false;
              } else {
                  this.$router.push("/thankyou");
              }
          }
      }
    },
    onChangeMode(mode) {
       this.set_mode(mode);
    },
    onLogout () {
       var logout = "api/logout";
       var lstat = "";
       console.log("Logout");
       this.$store.commit('setJWT', '');
       this.$store.commit('setLogState', false);
       this.$store.commit('setCurrentUser', '');
       this.$store.commit('setOperator', '');
       this.$store.commit('setReplyTo', '');
       this.$store.commit('newPage', 'Empty');
       this.$store.commit('setBanner', this.$store.state.logout_msg);
       this.$store.commit('setStatusMessage', '');
       localStorage.removeItem('id_token');
       localStorage.removeItem('current_user');
       localStorage.removeItem('operator');
       localStorage.removeItem('reply_to');
       localStorage.removeItem('logged_in');
       localStorage.removeItem('mode');
       axios.get(logout).then((res) => {
            lstat = res.data.data.status;
        }).catch((err) => {
            this.errors = err;
        });
        this.$router.push({ name: "home"});
    },
    userNotFound (user) {
      this.$store.commit('newPage', 'Empty');
      this.$store.commit('setBanner', "User not found: " + user);
    },
    
// TODO: Figure out where to get the current_user info and where to
// call this.
    saveMode (current_user, mode) {
       var url = "api/set-mode";
       var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                    };
       this.mode_updated = false;
       this.userData.logname = current_user;
       this.userData.mode = mode;
       axios.post(url, this.userData, config).then(
        (res) => {
          this.mode_updated = true; 
          console.log(this.userData);
          console.log(res.data);
       }).catch((err) => {
          this.errors = err;
       });
    },

    setCurrentUser (current_user, email) {
       var url = "api/get-popup-note/" + current_user;
       var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                    };
       axios.get(url, config).then((res) => {
            if (res.data.status) {
                if (res.data.data.note != "") {
                    this.modal_bodytext = "Last Modified:<em> " + res.data.data.lastmod + "</em>";
                    this.modal_bodytext += "<br><hr><br>";
                    this.modal_bodytext += "<pre>" + res.data.data.note + "</pre>";
                    this.modal_title = "Popup for " + current_user;
                    this.modal_alert = "left";
                    this.modal_footer = "";
                    this.isNoticeVisible = false;
                    this.isModalVisible = true;
                    //console.log("get POPUP " + res.data.data.note);
                 }
            }
        }).catch((err) => {
            this.errors = err;
        });

       this.$store.commit('setCurrentUser', current_user);
       this.$store.commit('setReplyTo', email);
       this.$store.commit('newPage', 'Empty');
       this.$store.commit('setBanner', 'SWCP Members Portal');
       this.$store.commit('setStatusMessage', '');
       localStorage.setItem('current_user', current_user);
       localStorage.setItem('reply_to', email);
       
    },
    getLogStatus () {
      return (typeof localStorage.id_token != "undefined");
    },
    getJwt () {
      return localStorage.getItem('id_token');
    },
    setPage (page, title, args) {
      this.$store.commit('newPage', page);
      this.$store.commit('setBanner', title);
      this.$store.commit('contentArgs', args);
      this.$store.commit('setStatusMessage', '');
      this.args = args;
    }
  }
}

</script>

<style>
#applet {
  font-family: 'Raleway', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: v-bind(app_color);
  background-color: v-bind(app_bgcolor);
  height: auto;
}
#applet .row {
   margin-right: 0px ! important;
}
#applet pre {
   color: v-bind(app-color);
}

#applet input[type="text"], textarea, select {
  background-color : v-bind(form_back) !important; 
  color: v-bind(form_text) !important;
  border-color: gray;
}
#applet input[type="password"] {
  background-color : v-bind(form_back) !important; 
  color: v-bind(form_text) !important;
  border-color: gray;
}
.btn-outline-primary, .btn-outline-info {
    color: v-bind(btn_link);
}

.content-col {
  margin-bottom: 50px;
}

.swcp-main {
  background-color: v-bind(app_bgcolor);
  color: v-bind(app_color);
}

.slipped_by_75 {
  margin-top: 75px;
}
.spaced {
  margin-left: 20px;
  margin-right: 20px;
}
.tap-right {
  margin-left: 40%;
}
.dbhead h1, h2 {
  padding-top: 30px;
  padding-bottom: 20px;
  color: v-bind(app_link);
}
.rainbow-slice {
  background-image: linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff);
  height: 10px;
  width: 100%;
}
.swcp-slice {
  background-image: linear-gradient(139deg, #0FC2E4, #034EA1, #2A21A8, #43197F);
  height: 10px;
  width: 100%;
  bottom: 0;
}
.attn-class {
   background-color: #ddeeff;
}
.blue-class {
   background-color: v-bind(blue_bgcolor) !important;
   color: v-bind(blue_text) !important;
}
.white-class {
   color: v-bind(app_color);
   background-color: v-bind(app_bgcolor);
}
.red-class {
   background-color: #ffaaaa;
   color: black ! important;
}
.gray-class {
   background-color: #dcdcdc;
}
h1, h2, h3 {
  font-weight: normal;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

ul.bullets {
  list-style-type: disc;
}

ul.bullets li {
  display: list-item;
}

.btn-link {
    color: v-bind(btn_link);
}

.btn-link:hover {
    color: v-bind(btn_link_hover);
}

a {
    color: v-bind(app_link);
}

a:hover {
    color: v-bind(btn_link_hover);
}

::placeholder {  
  color: #a2bbd3 !important;
}
.full-width {
  padding-left: 0px;
  padding-right: 0px;
}
.swcp-look-at-me {
  font-size: +1 !important;
  font-weight: bold;
  color: #ff0000 !important;
}
.swcp-em {
  font-style: italic !important;
  color: #d9824f !important;
}
.form-error {
  background: #bf2441;
  color: #fff;
  padding: 3px;
  margin-top: 0;
  font-weight: 600;
  font-size: 18px;
  width: 90%;
  border: 1px solid #880000;
  border-radius: 5px;
}
.invalid-large {
  background: #f69273;
  border: 1px black solid;
  color: black;
  padding: 10px;
  font-weight: 700;
  font-size: 20px;
  width: 90%;
  border-radius: 5px;
}
.invalid {
  background: #f69273;
  color: black;
  padding: 3px;
  margin-top: 0;
  font-weight: 600;
  font-size: 16px;
  width: 90%;
  border-radius: 5px;
}
.btn:focus {
  box-shadow: none;
}
.alert-serious {
  background-color:#fd969b;
  color:#000000;
  font-weight: bold;
}
.alert-custom {
  background-color:#6089D5;
  color:#fff;
}
.background-blue {
  background-color: #ccccff;
}
.btn-link {
  box-shadow: none !important;
}
.btn {
  border-radius: 1.4em;
}
.btn-light {
  border-width: 1px;
  box-shadow: 2px 2px 5px #888888;
}
.btn-dark {
  border-width: 2px;
  box-shadow: none;
}

.ducks {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.card-header-dark {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(255,255, 255, .03);
    border-bottom: 1px solid rgba(255, 255, 255, .125);
}
.inv-alert {
    background-color: #ddeeff;
    color: black;
    padding: 10px;
    text-align: center;
}
.inv-tab {
    background-color: #dddddd;
    color: black;
}

</style>
