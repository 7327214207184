<template>
  <div>
   <div class="row top-row">
     <h2 class="col-12">Add Web Hosting</h2>
        Under Construction ...
   </div>
  </div>

</template>

<script>

import axios from "axios";
import { useVuelidate } from '@vuelidate/core'
import { required, numeric, email, helpers } from '@vuelidate/validators';
import { not_negative } from '@/helpers/utils.js';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
      name: 'AddWebHosting',
      components: { ErrorMessage },
      emits: [ 'message' ],
      setup () {
         return { v$: useVuelidate() }
      },
      data () {
        return {
           result: [ ],
           pending: [ ],
           domains: [ ],
           domsel: 'Select a domain',
           userData: {
               did: ''
           },
           problems: {
               did: ''
           },
           fields: [ 'did' ],
         }
      },
      validations () {
        return {
           userData: {
               did: { required: helpers.withMessage('Select a domain', required) }
           }
        }
      },
      methods: {
         clearProbs() {
            this.fields.forEach ((item) => {
               this.problems[item] = '';
            });                  
         },             
         responseMessage(title, body, type, alert, footer, menu) {
                var args = { title: title, body: body,
                             type: type, alert: alert,
                             footer: footer, menu: menu };
                this.$store.commit('setStatus', args);
                this.$emit('message');
                return;
         },
         simple_url(dom) {
             return dom.virtname;
         },
         actual_url(dom) {
             var proto = "http://";
             if (dom.ssl == 1) { proto = "https://"; }
             return proto + dom.virtname;
         },
         selApp(event) {
             var index = event.target.value;
             this.userData.appname = this.webapps[index].appname;
             this.userData.subdir = this.webapps[index].urlext;
             this.userData.nsuphp = this.webapps[index].needs_suphp;
             this.userData.wfv = this.webapps[index].wf_versions;
         },
         setupApps() {
            this.getAppList();
            this.getVirtList();
            this.userData.email = this.customer_email;
         },
         clearForm() {
            this.userData.webappid = -1;
            this.userData.appname = '';
            this.userData.vid = '';
            this.userData.subdir = '';
            this.userData.email = '';
            this.userData.nsuphp = 0;
            this.userData.wfv = '';
         },
         checkPending () {
            this.domains = [ { 'name': 'calicocats.com', did: 1 },
                             { 'name': 'bluejaic.net', did: 2 }
                           ];
/*
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/pending-installations/" + this.account , config )
            .then (
                    response => {
                          this.pending = response.data.data.installations;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    })
*/
         },

         getAppList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/applist" , config )
            .then (
                    response => {
                          this.webapps = response.data.data;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.webapps = [ { 'webapp': 'unknown',
                                       'urlext': "",
                                       'aid': 0,
                                       'version': '1.0'  } ];
                    })
         },
         getVirtList () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            this.account = this.current_user;
            axios.get("/api/compressed-websites/" + this.account , config )
            .then (
                    response => {
                          this.domains = response.data.data;
                    })
            .catch(error => {
                    var notes = "";
                    if (error.response) {
                       if (error.response.data.message) {
                           notes = error.response.data.message;
                       }
                       if (error.response.data.status && error.response.data.status == "expired_token") {
                           this.$store.dispatch('expired');
                       }
                    }
                    this.domains = [ ];
                    })
         },
         async installDom () {
            var config = { headers:
                                { Authorization: "Bearer " + this.jwt }
                         };
            const result = await this.v$.$validate();
            var message, property, email;
            if (! result) {
                this.clearProbs(); // Set all problems to ''
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
            } else {
                this.account = this.current_user;
            
                axios.post("/api/installapp", this.userData, config )
                .then (
                        response => {
                              if (response.data.status) {
                                  this.responseMessage("Installing ...", this.userData.appname + " in " + response.data.data.url,
                                                       'thankyou', 'info', '', []);
                              } else {
                                  this.responseMessage("Failed ...", response.data.message, 'modal', 'serious', '', []);
                              }
                        })
                .catch(error => {
                        var notes = "";
                        if (error.response) {
                           console.log(error);
                           if (error.response.data.message) {
                               notes = error.response.data.message;
                               this.responseMessage("Failed", notes, 'modal', 'serious', '', []);
                           }
                           if (error.response.data.status && error.response.data.status == "expired_token") {
                               this.$store.dispatch('expired');
                           }
                        }
                        this.result = [ ];
                        })
             }
          }
      },
      computed: {
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
.selcss {
   padding: 10px;
   border: 1px solid dodgerblue;
   border-radius: 5px;
}
.table td, .table th {
    padding: .5rem .75rem;
}
.table th {
    color: #333333;
}
.top-row h1, h2, h3, h4, h5, h6 {
    margin-top: .5em;
}

</style>
