<template>
  <div class="container">
    <h2 class="dbhead">Change Account Address</h2>
    <p>For most people the account address is
       where your invoices will be mailed to. It's also how we'll communicate
       with you if we need to get in touch.</p>

       <p>If your bills are paid by someone other than the account holder, at a different
       address, you can set up a billing address so the invoices are sent there.
       You can do that with the <router-link v-bind:to="{ name: 'billing-addr' }">Billing Address Tool</router-link>.
       <em><b>Most people won't need to set up a billing address</b></em>.</p>

       <p>If you need to change the billing address for a credit card you have on file,
       please use the <router-link v-bind:to="{ name: 'edit-cc' }">Edit Credit Card Tool</router-link>. </p>

       <p>If you want to update the information associated with your domain registration 
       <router-link v-bind:to="{ name: 'send-auth-msg' }">send us a message</router-link>. Thanks! </p>
    
       <div class="col-12">
       <form  v-if="showme" v-on:clear-probs="clearProbs" v-on:submit.prevent="updateAccountAddr">
           <div class="form-group row">
               <h4 v-if="actual_logname != ''" class="demark">Account: {{actual_logname}}</h4>
           </div>
           <div class="form-group row">
               <h5 class="demark">Account Address </h5><br>
              <OnlyAddress
                   name_tag="Name"
                   hard=1
                   v-model:address="userData.address"
                   v-model:city="userData.city"
                   v-model:state="userData.state"
                   v-model:zipcode="userData.zipcode"
                   v-model:country="userData.country"
                   v-model:problems="problems"
                   :key="keymaster"
               >
               </OnlyAddress>

               <div class="col-sm-12">
                  <div class="form-group row">
                     <label class="col-sm-3">Telephone Number</label>
                     <input class="form-control col-sm-4" type="text" v-model="userData.phone" id="phone" />
                  </div>
               </div>
          </div>
          <div class="form-group row">
              <div class="offset-sm-3 col-sm-6">
                   <button :class="btn_mode"
                           type="submit">Update</button>
              </div>
              <div class="col-sm-3"></div>
         </div>
      </form>
      </div>
  </div>
</template>

<script>
   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core';
   import { required, email, helpers } from '@vuelidate/validators';
   import { flattenAddress } from '@/helpers/utils.js';
   import ErrorMessage from '@/components/ErrorMessage.vue';
   import OnlyAddress from '@/components/OnlyAddress.vue';
   export default {
      name: 'AccountAddress',
      emits: [ 'message' ],
      components: { ErrorMessage, OnlyAddress },
      data () {
        return {
           v$: useVuelidate(),
           pending: false,
           showme: 1,
           actual_logname: '',
           keymaster: 1,
           userData: {
               account: '',
               first_name: '',
               last_name: '',
               company: '',
               address: '',
               city: '',
               state: '',
               zipcode: '',
               country: 'US',
               phone: ''
           },
           problems: { address: '',
                       city: '',
                       state: '',
                       zipcode: '',
                       country: ''
                     },
           sendData: {
               send_from: 'swcpweb@swcp.com',
               send_to: 'accounting@swcp.com',
               subject: '',
               footer: '',
               message: ''
           },
           errors: [],
        }
      },
      mounted() {
          this.getAccountAddr();
      },
      watch: {
          current_user: function (newUser, oldUser) {
            this.getAccountAddr();
          }
      },
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body, 
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },
         fillForm( data ) {
           this.userData.address = data.address;
           this.userData.city = data.city;
           this.userData.state = data.state;
           this.userData.zipcode = data.zipcode;
           this.userData.country = data.country;
           this.userData.phone = data.phone;
           this.keymaster++;
         },
         clearForm() {
           this.userData.address = '';
           this.userData.city = '';
           this.userData.state = '';
           this.userData.zipcode = '';
           this.userData.country = 'US';
           this.userData.phone = '';
           this.keymaster++;
         },
         clearProbs () {
           this.problems['address'] = '';
           this.problems['city'] = '';
           this.problems['state'] = '';
           this.problems['zipcode'] = '';
           this.problems['country'] = '';
         },
         buildMessage ( data ) {
           var msg = "Account Address has been changed. \n";
           msg += "Please check to see that the new one is postal service correct.\n";
           msg += "---------------------------------------------------\n";
           msg += "Old Address:\n";
           msg += flattenAddress( data.old_address );
           msg += "---------------------------------------------------\n";
           msg += "New Address:\n";
           msg += flattenAddress( data.new_address );
           return msg;
         },
         sendMessage( msg ) {
            var url = "/api/sendmessage";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.sendData.message = msg;
            this.sendData.subject = "Account Address updated for " + this.current_user;
            this.sendData.footer = this.footer_tag;
            axios.post(url, this.sendData, config )
            .then (
                   response => {
                      this.response_status = response.data.status
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                          this.$store.dispatch('expired');
                      }
                      this.response_status = error;
                   })

         },
         getAccountAddr() {
            var url = "api/get-account-address";
            var error_msg = "Unknown error, please contact noc@swcp.com";
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            this.userData.account = this.current_user;
            axios.post(url, this.userData, config )
            .then (
                   response => {
                      if (response.data.status) {
                          this.fillForm(response.data.data.address);
                          this.actual_logname = response.data.data.logname;
                      } else {
                          if (typeof response.data.message !== 'undefined') { error_msg = response.data.message; }
                          this.responseMessage("Problem in Account Address change " , 
                                               error_msg, 'modal', 'serious', '', []);
                          this.showme = 0;
                      }
                   })
            .catch(error => {
                      if (error.response.data.status && error.response.data.status == "expired_token") {
                         this.$store.dispatch('expired');
                      }
                      this.responseMessage("Failed", "Failed to get account address " + error, 
                                           "modal", "serious", "", []);
                      this.clearForm();
                   })
         },
         async updateAccountAddr() {
            var url = "api/update-account-address";
            var hdr = "";
            var msg = "";
            var stat = 0;
            var property;
            var message;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            const result = await this.v$.$validate();
            if (this.pending) { console.log("processNewCard: submit while pending"); return; }
            this.clearProbs();
            if (! result) {
                this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      this.problems[property] = message;
                });
                this.responseMessage("", "", "reset", "warning", "", []); 
            } else {
                this.userData.account = this.current_user;
                this.pending = true;
 
                axios.post(url, this.userData, config )
                .then (
                       response => {
                          if (response.data.status) { 
                              this.responseMessage("Success", "Updated", "review", "info", "", []);
                              this.sendMessage(this.buildMessage(response.data.data));
                              this.pending = false;
                          } else {
                              this.responseMessage("Failed", "Unable to update - let NOC know", "modal", "serious", "", []);
                              this.pending = false;
                          }
                       })
                .catch(error => {
                          this.responseMessage('Update Failed',
                                  error.message, 'modal', 'warning', '', []);
                          this.pending = false;
                       })
             }
          }
      },
      computed: {
           footer_tag () {
             if (this.operator === this.current_user) {
                        return "Message sent by " + this.operator;
             } else {
                        return "Message sent by " + this.operator + " for " + this.current_user;
             }
           },
           user ()         { return this.$store.state.user; },
           current_user () { return this.$store.state.current_user; },
           operator ()     { return this.$store.state.operator; },
           staff ()        { return this.$store.state.staff; },
           jwt ()          { return this.$store.state.jwt; },
           mode ()         { return this.$store.state.mode; },
           btn_mode ()     { return "btn btn-outline-primary btn-" + this.mode; }
      }
   }

</script>

<style scoped>
.demark {
    border-bottom: 2px #458fea solid;
    padding-bottom: 4px;
    margin-bottom: 30px;
}
</style>
