<template>
       <h2 class='dbhead'> Add Secondary Accounts for <span class="swcp-em">{{current_user}}</span></h2>
  <div :class="div_class">
       <div class="row">&nbsp;</div>
       <div class="form-group row">
            <div class="col-3">
                 <b>Logname (up to 12 chars)</b>
            </div>
            <div class="col-3">
                 <b>First Name</b>
            </div>
            <div class="col-3">
                 <b>Last Name</b>
            </div>
            <div class="col-3">
                 <b>Email Only<br>Full Secondary (Websites)</b>
            </div>
       </div>
       <div class="row" v-for="(log,id) in userData.lognames" :key="id">
           <div class="col-md-3 col-sm-12  form form-inline">
                <label style="width: 10%"><i :class="invis_class(id)" /> &nbsp;</label>
                <input  style="width: 90%" placeholder="Logname" class="form-control" :name="lgname(id)" v-model="userData.lognames[id]" type="text"  />
           </div>
           <div class="col-md-3 col-sm-12 form form-inline">
                <input style="width: 100%" placeholder="First Name" class="form-control" :name="fnname(id)" v-model="userData.first_names[id]" type="text" />
           </div>
           <div class="col-sm-12 col-md-3 form form-inline">
                <input style="width: 100%" placeholder="Last Name" class="form-control" :name="lnname(id)" v-model="userData.last_names[id]" type="text"/>
           </div>
           <div class="col-sm-12 col-md-3 form ">
                Email Box &nbsp;<input :name="typename(id)" checked v-model="userData.types[id]" type="radio" value="Email" /><br>
                Full Secondary &nbsp;<input :name="typename(id)"  v-model="userData.types[id]" type="radio" value="Full" />
           </div>
       </div>
       <div class="row">&nbsp;</div>
       <div class="row justify-content-center">
               <button class="btn btn-info" :onclick="verifyLognames">Add Accounts</button>
       </div>
       <div class="row">&nbsp;</div>
  </div>
</template>

<script>
   import axios from "axios";

   export default {
      name: 'AddSecondaries',
      emits: [ 'message' ],
      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return; 
         },           

         clearFormState() {
            let len = this.formState.length;
            console.log("Clearing formstate: len=" + len);
            for (let i = 0; i < len; i++) {
                this.formState[i] = '';
            }
            console.log(this.formState);
         },
         clearForm() {
            let len = this.formState.length;
            for (let i = 0; i < len; i++) {
                this.formState[i] = '';
                this.userData.lognames[i] = '';
                this.userData.first_names[i] = '';
                this.userData.last_names[i] = '';
                this.userData.types[i] = 'Email';
            }
         },
         invis_class (id) { 
               if (this.formState[id] == "") {
                  return "fa fa-bug invis-" + this.mode; 
               } else {
                  return "fa fa-bug visible"; 
               }
         },
         setErrors(list) {
            let errors = "";
            let header = "";
            list.forEach ((item) => {
               if (! item.status) {
                   header = "Please choose a different account name for these accounts: ";
                   this.formState[item.id] = 'exists';
                   errors += " " + item.logname;
               }
            })
            return header + errors;
         },
         verifyLognames() {
            let config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            let account = this.current_user;
            let url="api/check-account-names";
            let errorlist = "";
            this.userData.account = account;
            this.clearFormState();
            axios.post(url, this.userData, config )
            .then (
                 response => {
                   let mylist = response.data.data.list;
                   if (response.data.data.available) { this.requestAccounts(); }
                   else { 
                      errorlist = this.setErrors(mylist); 
                      this.responseMessage('Account names used or invalid', errorlist, 'modal', 'serious', '', [])
                   }
                   console.log(mylist);
                 })
            .catch(error => {
                   if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                   }
            })
         }, 
         requestAccounts() {
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            var account = this.current_user;
            var url="api/request-accounts";
            this.userData.account = account;
            axios.post(url, this.userData, config )
            .then (
                 response => {
                      this.responseMessage('New Accounts Pending', this.success_message, 'thankyou', 'info', '', []);
                 })
            .catch(error => {
                   if (error.response.data.status && error.response.data.status == "expired_token") {
                              this.$store.dispatch('expired');
                   }
            })

         },
         lgname   (id) { return "lognames[" + id + "]"; },
         fnname   (id) { return "first_names[" + id + "]"; },
         lnname   (id) { return "last_names[" + id + "]"; },
         typename (id) { return "types[" + id + "]"; },
      },
      computed: {  
         user () { return this.$store.state.user; },
         current_user () { return this.$store.state.current_user; },
         jwt () { return this.$store.state.jwt; },
         mode () { return this.$store.state.mode; },
         div_class () { return "col-sm-12 grayline-" + this.mode; },
      },     
      data () {
         return {
           success_message: "Thanks for sending a request to create new accounts. We'll process this as soon as " +
                            "possible. Look for a message from help@swcp.com when your new accounts are " +
                            "ready. If you have any questions, email us at help@swcp.com or call us at 505-232-7992.<br><br>" +
                            "Once the accounts are created they will be visible from this dashboard.\n",
           title: 'Add accounts for ' + this.current_user,
           userData: {
               account: '',
               lognames: [ '', '', '', '', '', '', '', '', ''],
               first_names: [ '', '', '', '', '', '', '', '', ''],
               last_names: [ '', '', '', '', '', '', '', '', ''],
               types: [ 'Email', 'Email', 'Email', 'Email', 'Email', 'Email', 'Email', 'Email', 'Email']
           },
           formState: [ '', '', '', '', '', '', '', '', '']
         }
      }
   }

</script>

<style scoped>
::placeholder { 
  color: #a2bbd3 !important;
}
.grayline-dark {
  border: 1px solid #555555;
  background-color: #3e444a;
}
.grayline-light {
  border: 1px solid #dddddd;
  background-color: #f8f8f8;
}
.invis-dark {
  color: #3e444a;
}
.invis-light {
  color: #f8f8f8;
}
.visible {
  color: red;
}
</style>
