<template>
  <div>
     <h2 class="dbhead">Subscribe to The Portal</h2>

   <div class="row">
     <h3>What is the Portal?</h3>
     <p>The Portal is Southwest Cyberport's monthly newsletter. 
 We cover current topics of interest from Block
 Chain technology, Artificial Intelligence, and Robotics, to how to protect
 yourself from phone and email scams. If you want to get the Portal as soon
 as it's out, subscribe now. If you need to stop receiving the portal 
 you can drop a message to <b>help@swcp</b> anytime and we'll remove you from the
 list. </p>
  <p>Past newsletters can be found <a target="_subport" href="https://www.swcp.com/newsletters/">here</a></p>
  </div>
   <div class="row">
     <div class="col-sm-12">
         <br>
         <form class="form-group my-2 my-lg-0" @submit.prevent="sendMsg">
            <div class="row justify-content-md-center">
                       <ErrorMessage :error="problems.email" />
            </div>
           <div class="row form-group">
            <label class="col-sm-2">Email Address</label> 
            <input class="col-sm-10 form-control" type="text" name="email"
                      v-model="userData.email"
                      aria-label="Reply To" /><br>
           </div>
            <div class="tap-right">
              <button :class="btn_mode"
                  type="submit">Subscribe</button>
            </div>
         </form>
     </div>
   </div>
 </div>
</template>

<script>
   const fail_title = "Sorry, something has gone wrong.";
   const fail_body = "Your subscription was not added<br>" +
                     "You can follow up with us at (505) 232-7992 or help@swcp.com."; 
   const success_title = "Welcome to the Portal!";
   const success_body = "Thanks for subscribing to the Portal. " +
                        "If you need to follow up you can reach us at (505) 232-7992";

   import axios from "axios";
   import { useVuelidate } from '@vuelidate/core';
   import { required, email, helpers } from '@vuelidate/validators';
   import ErrorMessage from '@/components/ErrorMessage.vue';

   export default {
      name: 'SendAuthMsg',
      components: { ErrorMessage },
      emits: [ 'message' ],
      data () {
        return {
           userData: {
               send_to: 'cheeks@swcp.com',
               send_from: 'website@swcp.com',
               subject: 'Subscribe to the Portal',
               email: '',
               reply_to: this.customer_email,
               footer: ''
           },
           problems: {
               email: ''
           },
           is_visible: false,
           response_status: ''
        }
      },
      validations () {     
        return {
           userData: {
              email: { required, email }
           }  
        }
      },
      setup () {
        return { v$: useVuelidate() }
      },

      methods: {
         responseMessage(title, body, type, alert, footer, menu) {
           var args = { title: title, body: body,
                        type: type, alert: alert,
                        footer: footer, menu: menu };
           this.$store.commit('setStatus', args);
           this.$emit('message');
           return;
         },

         async sendMsg({ e }) {
            var url = "/api/sendmessage"; 
            var rep = this.customer_email;
            var msg = "";
            var message = "";
            var property;
            var config = { headers:
                            { Authorization: "Bearer " + this.jwt }
                         };
            if (this.userData.reply_to != "") { 
                rep = this.userData.reply_to;
            }
            const result = await this.v$.$validate();
            if (! result) {
                  msg = "Validation problems found:";
                  this.v$.$errors.forEach (err => {
                      property = err.$property;
                      message = err.$message;
                      if (message != "") {
                          msg += " " + property + "=" + message + "\n";
                      }
                      this.problems[property] = message;
                  });
                  console.log(msg);
             } else {
            this.userData.send_from = rep;
            this.userData.footer = this.footer_tag; 
            this.userData.message = "Please subscribe " + this.userData.email + " to the Portal";
            axios.post(url, this.userData, config )
            .then (
                   response => {
                      this.response_status = response.data.status;
                      this.responseMessage(success_title, success_body, 'thankyou', 'info', '', []);
                   })
            .catch(error => {
                      if (error.response) {
                         if (error.response.data.status && error.response.data.status == "expired_token") {
                             this.$store.dispatch('expired');
                         }
                      }
                      this.response_status = error;
                      this.responseMessage(fail_title,fail_body, 'modal', 'serious', '', []);
                   })
          }
          }
      },
      mounted () {
           this.userData.reply_to = this.customer_email;
      },
      watch: {
          current_user: function (newUser, oldUser) {
             this.userData.reply_to = this.customer_email;
          }
      },
      computed: {
          footer_tag () {
            if (this.operator === this.current_user) {
                       return "Message sent by " + this.operator;
            } else {
                       return "Message sent by " + this.operator + " for " + this.current_user;
            }
          },
          operator ()       { return this.$store.state.operator; },
          current_user ()   { return this.$store.state.current_user; },
          jwt ()            { return this.$store.state.jwt; },
          customer_email () { return this.$store.state.reply_to; },
          mode ()           { return this.$store.state.mode; },
          btn_mode ()       { return "btn btn-outline-primary my-2 my-sm-0 btn-" + this.mode; }
      }
   }
</script>

<style>
.rsam-info.card-body {
   background-color: #ffffee;
   margin-bottom: 15px;
}
.sam-info.card-body {
   background-color: #f5e6c5;
}
</style>
